import React from 'react';
import ExternalLanding from "../ExternalLanding";
import useHubSpotUTMQueryParams from "../../hooks/useHubSpotUTMQueryParams";

const GmsLanding = () => {
  const searchParams = useHubSpotUTMQueryParams();
  const constructedUrl = `https://launch.secur.space/gmslanding?${searchParams.toString()}`;
  return <ExternalLanding url={constructedUrl} height="100%" />
}

export default GmsLanding;