import {Theme} from "@mui/material";
import {makeStyles} from "@mui/styles";

export const useStyles: (theme: Theme) => {
  mainContainer: CSSStyleSheet,
  filterContainer: CSSStyleSheet,
  rightAlign: CSSStyleSheet,
  tabPanel: CSSStyleSheet
} = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: '2.5rem',
    paddingBottom: '2.86rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '8.04rem',
      paddingRight: '8.04rem',
    }
  },
  filterContainer: {
    marginTop: '2em'
  },
  tabPanel: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  rightAlign: {
    display: 'flex',
    justifyContent: "flex-end",
    padding: '0 2em 1em 0'
  },
}));