import React, {memo} from 'react';
import {Grid, Typography, Theme} from "@mui/material";
import PropTypes from "prop-types";
import type {Account} from "../types/Account";
import PartnerFundingSourceItem from "./paymentMethods/PartnerFundingSourceItem";
import {determinePaymentImage} from "../util/PaymentUtils";
import { makeStyles } from '@mui/styles';
import {BankAccountStatus} from "./constants/securspace-constants";

const useStyles: (theme: Theme) => {
  listContainer: CSSStyleSheet,
} = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    border: `0.07rem solid ${theme.palette.grey[200]}`,
    borderRadius: '0.57rem',
  }
}));

const PartnerFundingSourcesList = memo(
  function PaymentMethodsList(props: {account: Account, onMenuAction: () => void, fundingSourceExists: boolean}) {
    const {account, onMenuAction, fundingSourceExists} = props;
    const classes = useStyles();

    const fundingSourceVerified = () => {
      if (account.fundingSourceVerified === false) {
        return BankAccountStatus.UNVERIFIED;
      } else if (account.fundingSourceVerified === true) {
        return BankAccountStatus.VERIFIED;
      } else {
        return null;
      }
    }

    return (
      <Grid container direction={'column'} className={classes.listContainer}>
        { fundingSourceExists ? <PartnerFundingSourceItem
          bankName={account.existingFundingSourceBankName}
          bankAccountType={account.existingFundingSourceBankAccountType}
          nickName={account.existingFundingSourceAccountNickname}
          status={fundingSourceVerified()}
          img={determinePaymentImage({type: 'BANK'})}
          onMenuAction={onMenuAction}
        />
          : <Typography variant={'h5'} component={'strong'}>No Funding Source added yet</Typography>
        }
      </Grid>
    );
  });

PartnerFundingSourcesList.propTypes = {
  account: PropTypes.object,
  onMenuAction: PropTypes.func,
};

export default PartnerFundingSourcesList;
