import React from 'react';
import ExternalLanding from "./ExternalLanding";
import useHubSpotUTMQueryParams from "../hooks/useHubSpotUTMQueryParams";

export const Contact = () => {
  const searchParams = useHubSpotUTMQueryParams();
  const constructedUrl = `https://launch.secur.space/contact-us?${searchParams.toString()}`;
  return <ExternalLanding url={constructedUrl} height="100%"/>
}

export default Contact;
