import { getFormattedEquipmentTypes } from "../../requests/type-requests";

const headerCells = [
    {
        id: 'activity',
        label: 'Activity'
    },
    {
        id: 'activityDate',
        label: 'Date'
    },
    {
        id: 'containerNumber',
        label: 'Container'
    },
    {
        id: 'trailerNumber',
        label: 'Trailer'
    },
    {
        id: 'chassisNumber',
        label: 'Chassis'
    },
    {
        id: 'truckLicensePlateNumber',
        label: 'Truck License Plate Number'
    },
    {
        id: 'chassisLicensePlateNumber',
        label: 'Chassis License #'
    },
    {
        id: 'assetSize',
        label: 'Size'
    },
    {
        id: 'driverFirstName',
        label: 'First Name (Driver)'
    },
    {
        id: 'driverLastName',
        label: 'Last Name (Driver)'
    },
    {
        id: 'driverLicenseNumber',
        label: 'License (Driver)'
    },
    {
        id: 'sealNumber',
        label: 'Seal'
    },
    {
        id: 'assetType',
        label: 'Type'
    },
    {
        id: 'orderNumber',
        label: 'Booking'
    },
    {
        id: 'locationName',
        label: 'Location'
    },
    {
        id: 'supplierName',
        label: 'Supplier'
    },
    {
        id: 'currentInventory',
        label: 'Inventory'
    },
    {
        id: 'correctionStatus',
        label: 'Correction Status'
    }
];

const filterBySelectOption = (snackbarShowMessage) => [
    {
        label: "Equipment Type",
        value: "",
        name: "equipmentType",
        option: getFormattedEquipmentTypes(snackbarShowMessage)
    }
];
const groupOption = [
    {
        value: 'activity',
        label: 'Activity'
    },
    {
        value: 'activityDate',
        label: 'Activity Date'
    },
    {
        value: 'containerNumber',
        label: 'Container'
    },
    {
        value: 'trailerNumber',
        label: 'Trailer'
    },
    {
        value: 'chassisNumber',
        label: 'Chassis'
    },
    {
        value: 'chassisLicensePlateNumber',
        label: 'Chassis License #'
    },
    {
        value: 'assetSize',
        label: 'Size'
    },
    {
        value: 'driverFirstName',
        label: 'First Name (Driver)'
    },
    {
        value: 'driverLastName',
        label: 'Last Name (Driver)'
    },
    {
        value: 'driverLicenseNumber',
        label: 'License (Driver)'
    },
    {
        value: 'sealNumber',
        label: 'Seal'
    },
    {
        value: 'assetType',
        label: 'Type'
    },
    {
        value: 'orderNumber',
        label: 'Booking'
    },
    {
        value: 'locationName',
        label: 'Location'
    },
    {
        value: 'supplierName',
        label: 'Partner'
    },
    {
        value: 'currentInventory',
        label: 'Inventory'
    }
];
const propertiesToCheck = ['activity', 'activityDate', 'containerNumber', 'trailerNumber',  'chassisNumber', 'chassisLicensePlateNumber', 'assetSize', 'driverFirstName', 'driverLastName', 'driverLicenseNumber', 'sealNumber', 'assetType', 'orderNumber', 'locationName', 'supplierName', 'currentInventory'];

export {
    headerCells,
    filterBySelectOption,
    groupOption,
    propertiesToCheck
}