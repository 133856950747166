import React, { useState, useEffect } from 'react'
import { Typography, Grid } from "@mui/material";
import { Pagination, InfoCardLoader } from '@securspace/securspace-ui-kit'
import { withSnackbar } from '../../components/hocs/withSnackbar'
import { BuyerInventoryFilter, BuyerInventoryTable } from '../../components/buyer-inventory';
import { getErrorMessageForStandardResponse } from '../../util/NetworkErrorUtil';
import { getAllBuyerInventory } from '../../components/buyer-inventory/requests/buyer-inventory-requests';
import { expandAllItems, toggleItem } from '../invoices/BuyerInvoicesReport';
import BuyerInventoryReportDataSummary from "../../components/buyer-inventory/BuyerInventoryReportDataSummary";
import {AssetType, AssetTypeLabel} from "../../components/constants/securspace-constants";
import useUrlQueryParams from '../../hooks/useUrlQueryParams';

const BuyerInventoryReport = ({ account, snackbarShowMessage, filterSelectOptions }) => {

    const {initOrderNumber, initEquipmentNumber} = useUrlQueryParams();

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(50);
    const [totalNumberResults, setTotalNumberResults] = useState(0);
    const [selectedLocation, setSelectedLocation] = useState();
    const [sortBy, setSortBy] = useState('');
    const [equipmentType, setEquipmentType] = useState('')
    const [equipmentNumber, setEquipmentNumber] = useState(initEquipmentNumber);
    const [bookingNumber, setBookingNumber] = useState(initOrderNumber)
    const [sortDir, setSortDir] = useState('')
    const [expandedItems, setExpandedItems] = useState({});
    const [expandedAll, setExpandedAll] = useState(false);
    const [inventoryReports, setInventoryReports] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const { id } = account;
        if (id) {
            setLoading(true)
            const requestParams = {
                buyerAccountId: id,
                page: page,
                size: size,
                sortBy: sortBy,
                locationId: selectedLocation?.id,
                equipmentType: equipmentType === AssetType.ALL ? null : equipmentType,
                bookingNumber: bookingNumber?.trim(),
                sortDir,
                equipmentNumber
            }

            getAllBuyerInventory(requestParams)
                .then(({ body }) => {
                    const { content, count } = body;
                    const data = content?.map((item) => {

                        return {
                            ...item,
                            assetType: AssetTypeLabel[item.assetType] || item.assetType,
                        };
                    });
                    setInventoryReports(data)
                    setTotalNumberResults(count);
                    setLoading(false)
                    setExpandedItems({})
                })
                .catch(err => {
                    snackbarShowMessage(getErrorMessageForStandardResponse(err), "error", 15000)
                    setLoading(false)
                });
        }

    }, [account, page, size, equipmentNumber, selectedLocation, equipmentType, bookingNumber, sortBy, sortDir]);

    const handlePageChange = (_, selectedPage) => {
        setPage(selectedPage)
    }

    const handleSizeChange = (event) => {
        setSize(event.target.value)
    }

    
const handleToggle = (id) => toggleItem(id, setExpandedItems);
const handleExpandAll = () => expandAllItems(inventoryReports, 'id', setExpandedItems, expandedAll, setExpandedAll);

// TODO EL: EquipmentType update is not being applied to search results

    return (
        <Grid item container flexDirection={'column'}>
            <Typography variant='h5' component='h1' mb={4}>Current Inventory</Typography>
            <Grid mb={4}>
                <BuyerInventoryFilter
                    buyerAccountId={account.id}
                    setSortBy={setSortBy}
                    setSelectedLocation={setSelectedLocation}
                    setEquipmentType={setEquipmentType}
                    setBookingNumber={setBookingNumber}
                    setSortDir={setSortDir}
                    setEquipmentNumber={setEquipmentNumber}
                    bookingNumber={bookingNumber}
                    equipmentNumber={equipmentNumber}
                    filterSelectOptions={filterSelectOptions} 
                />
            </Grid>
            {inventoryReports.length > 0 &&
                <BuyerInventoryReportDataSummary
                    accountId={account?.id}
                    query={{
                        bookingNumber: bookingNumber?.trim(),
                        buyerAccountId: account?.id,
                        equipmentNumber,
                        equipmentType: equipmentType === 'All' ? '' : equipmentType,
                        locationId: selectedLocation?.id,
                        sortBy,
                        sortDir,
                    }}
                    title={selectedLocation?.locationName || 'All'}
                    count={inventoryReports?.length}
                    expandedAll={expandedAll}
                    setExpandedItems={setExpandedItems}
                    setExpandedAll={setExpandedAll}
                    isShowCollapseButton={true}
                    handleExpandAll={handleExpandAll}
                    expandedItems={expandedItems}
                />
            }
            {
                loading ? [...Array(10).keys()].map((_, index) => (
                    <InfoCardLoader key={index} />
                )) : <BuyerInventoryTable
                    inventoryReports={inventoryReports}
                    locationId={selectedLocation?.id || ''}
                    equipmentType={equipmentType}
                    bookingNumber={bookingNumber}
                    sortBy={sortBy}
                    handleToggle={handleToggle}
                    expandedItems={expandedItems}
                />
            }

            {inventoryReports && inventoryReports.length > 0 && <Grid item>
                <Pagination
                    count={totalNumberResults}
                    rowsPerPage={size}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleSizeChange}
                    variant="tablePagination" />
            </Grid>}
        </Grid>
    )
}

export default withSnackbar(BuyerInventoryReport)
