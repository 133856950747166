import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Button, TextField, } from "@mui/material";
import { DatePicker, SelectInput } from "@securspace/securspace-ui-kit";
import { adjustBookingTypes } from "../../views/supplier-booking/SupplierBooking";
import { DateFormats } from "../constants/securspace-constants";
import { adjustEndDateRecurring, adjustNumberOfSpaces, adjustEndDate } from "../../views/requests/booking-requests";
import { getErrorMessageForNonStandardAndStandardResponse } from "../../util/NetworkErrorUtil";
import { withSnackbar } from "../hocs/withSnackbar";
import { getRecurringProperties } from "./util";
import moment from "moment";

const AdjustBookingDialog = ({open, onClose, onConfirm, type, booking, handleAdjustEndDate, handleAdjustSpaces, snackbarShowMessage}) => {

    const [recurringDateSelectOptions, setRecurringDateSelectOptions] = useState([]);
    const [selectedNewEndDate, setSelectedNewEndDate] = useState();

    const  RECURRING = 'RECURRING';

    const submitUpdateSpaces = () => {
        
        let requestBody = {};

        if(booking) {
            const {numberOfSpaces, brokeredSupplierPaidPerOccurrence, brokeredBuyerChargedPerOccurrence, id} = booking;

            requestBody.numberOfSpaces = numberOfSpaces;
            requestBody.bookingId = id;
            requestBody.brokeredSupplierPaidPerOccurrence = brokeredSupplierPaidPerOccurrence;
            requestBody.brokeredBuyerChargedPerOccurrence = brokeredBuyerChargedPerOccurrence;
        }

        adjustNumberOfSpaces(requestBody)
            .then(({body}) => {
                onConfirm();
                snackbarShowMessage(`Successfully updated number of spaces for booking ${body.orderNumber}`, 'success');
            }).catch(err => snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(err), 'error'))
            .finally( onClose() )

    }

    const submitUpdateDate = () => {
        
        if(booking) {
            let requestBody = {bookingId: booking.id};

            if(booking.frequency === RECURRING) {
                const {value} = selectedNewEndDate;
                requestBody.endDate = moment(value).format('M/DD/YYYY');

                adjustEndDateRecurring(requestBody)
                .then(({body}) => {
                    onConfirm();
                    snackbarShowMessage(`Successfully updated end date for booking ${body.orderNumber}`, 'success');
                }).catch(err => snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(err), 'error'))
                .finally( onClose() )

            } else {
                requestBody.endDate = moment(booking.endDate).format(DateFormats.DAY);

                adjustEndDate(requestBody)
                .then(({body}) => {
                    onConfirm();
                    snackbarShowMessage(`Successfully updated end date for booking ${body.orderNumber}`, 'success');
                }).catch(err => snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(err), 'error'))
                .finally( onClose() )
            }
        }

    }

    const handleSubmit = () => {
        if(type === adjustBookingTypes.DATE) {
            submitUpdateDate()
        } else {
            submitUpdateSpaces()
        }
    };

    const updateSpaces = (event) => {
        if (event.target && type === adjustBookingTypes.SPACES) {
            const {value} = event.target;
            handleAdjustSpaces(value)
        }
    }

    const handleSelectNewEndDateRecurringBooking = (event) => {
        const {value} = event?.target;

        setSelectedNewEndDate(recurringDateSelectOptions.find(item => item.value === value));
    }
    
    useEffect(() => {
        if(booking?.frequency === RECURRING) {
            setRecurringDateSelectOptions(getRecurringProperties(booking))
        }
    }, [booking]);

    useEffect(() => {
        const dateMatch = recurringDateSelectOptions.find(option => {
            const endDate = moment(booking?.endDate)
            return moment(option.value).isSame(endDate);
        })
        setSelectedNewEndDate(dateMatch)
    }, [recurringDateSelectOptions]);


    return (
        <Dialog open={open}>
            <DialogTitle>Adjust {type}</DialogTitle>
            <DialogContent>
                {
                    type === adjustBookingTypes.DATE ? 
                        <FormControl variant={'standard'} fullWidth>
                            {
                                booking?.frequency === RECURRING ? 
                                    <SelectInput
                                        label="End Date"
                                        value={selectedNewEndDate?.value}
                                        onChange={handleSelectNewEndDateRecurringBooking}
                                        selectData={recurringDateSelectOptions}
                                        hasAction={false}
                                    />
                                :
                                    <DatePicker 
                                        label="End Date"
                                        value={moment(booking?.endDate).format(DateFormats.DAY)}
                                        onChange={(event) => handleAdjustEndDate(moment(event).format(DateFormats.DAY))}
                                    />
                            }
                        </FormControl>
                    :
                        <FormControl variant={'standard'} fullWidth>
                            <TextField type="number" placeholder="Enter the new number of spaces" value={booking?.numberOfSpaces} onChange={updateSpaces}/>
                        </FormControl>
                }
            </DialogContent>
            <DialogActions>
                <Button color={'primary'} onClick={onClose}>Cancel</Button>
                <Button color={'primary'} onClick={handleSubmit}>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}

export default withSnackbar(AdjustBookingDialog);