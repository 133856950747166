import request from '../../../util/SuperagentUtils';

const getAllLocationsBySupplier = (supplierAccountId) => {
    return request.get(`/api/suppliers/${supplierAccountId}/space-utilization`);
};

const getSupplierBooking = (
    searchParams: {
        supplierAccountId: string,
        locationId?: string,
        buyerName?: string,
        bookingNumber?: string,
        equipmentType?: string,
        frequency?: string,
        status?: string,
        page?: number,
        size?: number,
        sortDir?: string,
        sortBy?: string,
    },
    onSuccess,
    onFail
) => {

    const { supplierAccountId, locationId, buyerName, bookingNumber, equipmentType, frequency, status, page, size, sortDir, sortBy } = searchParams;

    let pageParam = ["page", page || 0];
    let sizeParam = ["size", size || 10];

    let params = new URLSearchParams([pageParam, sizeParam]);

    if (locationId) {
        params.append("locationId", locationId)
    }
    if (buyerName) {
        params.append("buyerName", buyerName)
    }
    if (equipmentType && equipmentType !== "All") {
        params.append("assetTypeGroup", equipmentType)
    }
    if (status) {
        params.append("status", status)
    }
    if (frequency) {
        params.append("frequency", frequency)
    }
    if (sortBy) {
        params.append("sortBy", sortBy)
    }
    if (sortDir) {
        params.append("sortDir", sortDir)
    }
    if (bookingNumber) {
        params.append("bookingNumber", bookingNumber)
    };

    const result = request.get(`/api/booking/suppliers/${supplierAccountId}?${params}`);
    
    if (onSuccess && onFail) {
        result
        .then(onSuccess)
        .catch(onFail)
    } else {
        return result
    }
};

const getSupplierBookingCSVDownload = (searchParams) => {

    const { supplierAccountId, selectedLocation, buyerName, bookingNumber, equipmentType, frequency, sortDir, sortBy } = searchParams;
    const locationId = selectedLocation?.id;
    const queryParams = new URLSearchParams([]);
    
    if (locationId) queryParams.append("locationId", locationId);
    if (buyerName) queryParams.append("buyerName", buyerName);
    if (equipmentType && equipmentType !== "All") queryParams.append("assetTypeGroup", equipmentType);
    if (frequency) queryParams.append("frequency", frequency);
    if (bookingNumber) queryParams.append("bookingNumber", bookingNumber);;
    if (sortBy) queryParams.append("sortBy", sortBy);
    if (sortDir) queryParams.append("sortDir", sortDir);
    
    return `/api/booking/suppliers/${supplierAccountId}/report?${queryParams}`;
}

const updateBookingStatus = (data, onSuccess, onFail) => {
    const { status, body} = data;
    const result = request.post(`/api/booking/${status}`).send(body);

    if (onSuccess && onFail) {
        result
            .then(onSuccess)
            .catch(onFail)
    } else {
        return result
    }
}

export {
    getAllLocationsBySupplier,
    getSupplierBooking,
    getSupplierBookingCSVDownload,
    updateBookingStatus
}