import moment from "moment";
import { DateFormats } from "../constants/securspace-constants";
import { FREQUENCY_TYPE_WEEKLY, FREQUENCY_TYPE_MONTHLY } from "../../controls/FrequencyOption";
import { FrequencyOptions } from "../../controls/FrequencyOption";

const getUpdatedRecurringProperties = (startDate, recurringBooking, selectedFrequencyType, maxNumberOfIterations) => {
    if (recurringBooking) {
        let frequencyOptions = new FrequencyOptions(startDate);

        let frequencyTypeOptions = frequencyOptions.createOptions(selectedFrequencyType, maxNumberOfIterations);

        return (formatFrequencyOptions(frequencyTypeOptions))
    } else {
        return (formatFrequencyOptions(frequencyTypeOptions))
    }
};

export const getRecurringProperties = (booking) => {
    if (booking.durationType === 'WEEKLY') {
        const diffBetweenNowAndStartDate = moment(booking.startDate, DateFormats.DAY).diff(moment(), 'weeks');
        if (diffBetweenNowAndStartDate < 0) {
            const numberOfRecurrences = Math.abs(diffBetweenNowAndStartDate) + FREQUENCY_TYPE_WEEKLY.maxNumberOfRecurrences;
            return getUpdatedRecurringProperties(booking.startDate, true, FREQUENCY_TYPE_WEEKLY, numberOfRecurrences);
        } else {
            return getUpdatedRecurringProperties(booking.startDate, true, FREQUENCY_TYPE_WEEKLY);
        }
    } else {
        const diffBetweenNowAndStartDate = moment(booking.startDate, DateFormats.DAY).diff(moment(), 'months');
         if (diffBetweenNowAndStartDate < 0) {
             const numberOfRecurrences = Math.abs(diffBetweenNowAndStartDate) + FREQUENCY_TYPE_MONTHLY.maxNumberOfRecurrences;
             return getUpdatedRecurringProperties(booking.startDate, true, FREQUENCY_TYPE_MONTHLY, numberOfRecurrences);
         } else {
            return getUpdatedRecurringProperties(booking.startDate, true, FREQUENCY_TYPE_MONTHLY);
         }
    }
}

const formatFrequencyOptions = (legacyFrequencyOptions) => legacyFrequencyOptions.map(item => ({label: item.displayValue, value: item.endDate }));
