import {Tab, Tabs} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, {useState, useMemo} from 'react';
import { filterBySelectOption } from '../../components/interchanges/data';
import PropTypes from 'prop-types';
import {TabContext, TabPanel} from "@mui/lab";
import {Theme} from "@mui/material";
import BuyerSpaceUsageReport from '../spaceUsage/BuyerSpaceUsageReport';
import SecurSpaceContainer from '../../components/common/SecurSpaceContainer'
import BuyerInvoicesReport from '../invoices/BuyerInvoicesReport';
import InterChanges from '../interchanges/BuyerInterChangesReport';
import BuyerInventoryReport from "../buyer-inventory/BuyerInventoryReport";
import { withSnackbar } from '../../components/hocs/withSnackbar';

const useStyles: (theme: Theme) => {
  borderBottomGrey200: CSSStyleSheet,
  marginRight: CSSStyleSheet,
  mainContainer: CSSStyleSheet
} = makeStyles(theme => ({
  borderBottomGrey200: {
    borderBottom: `${theme.palette.grey[200]} solid 0.07rem`
  },
  marginRight: {
    marginRight: "0.5em"
  },
  mainContainer: {
    paddingTop: '2.5rem',
    paddingBottom: '2.86rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [theme.breakpoints.up('sm')]: {
        paddingLeft: '8.04rem',
        paddingRight: '8.04rem',
    }
}
}));

const ReportOverview = (props) => {
  const { initialTab = 0, account, snackbarShowMessage } = props;
  const [currentTab, setCurrentTab] = useState(initialTab);

  const classes = useStyles();

  const handleTabChange = (_event, value) => {
    setCurrentTab(value);
  }

  const filterSelectOptions = useMemo(() => filterBySelectOption(snackbarShowMessage), [filterBySelectOption]);

  return (
    <SecurSpaceContainer className={classes.mainContainer}>
      <TabContext value={currentTab + ''}>
        <Tabs
          variant={'fullWidth'}
          value={currentTab}
          onChange={handleTabChange}
          aria-label={'Report Tabs'}
          className={classes.borderBottomGrey200}
          textColor={'secondary'}
        >
          <Tab label='Current Inventory'/>
          <Tab label='Interchanges'/>
          <Tab label='Invoices'/>
          <Tab label='Space Usage'/>
        </Tabs>
        <TabPanel value={'0'}>
          <BuyerInventoryReport account={account} filterSelectOptions={filterSelectOptions} />
        </TabPanel>
        <TabPanel value={'1'}>
          <InterChanges account={account} filterSelectOptions={filterSelectOptions} />
        </TabPanel>
        <TabPanel value={'2'}>
          <BuyerInvoicesReport account={account}/>
        </TabPanel>
        <TabPanel sx={{
          padding:'1.5em 0em'
        }} value={'3'}>
          <BuyerSpaceUsageReport account={account} classes={classes}/>
        </TabPanel>
      </TabContext>
    </SecurSpaceContainer>
  );
};

ReportOverview.propTypes = {
  initialTab: PropTypes.number,
  account: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default withSnackbar(ReportOverview);
