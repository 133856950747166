import React, {useEffect, useState, useCallback} from 'react';
import {transformBooking} from '../../components/supplier-booking/data';
import {Container, Grid, Tab, Tabs, Typography} from "@mui/material";
import AdjustBookingDialog from '../../components/supplier-booking/AdjustBookingDialog';
import {TabContext, TabPanel} from "@mui/lab";
import type {Account} from "../../types/Account";
import BookingTab from '../../components/supplier-booking/BookingTab';
import {SupplierBookingFilter} from '../../components/supplier-booking';
import {withSnackbar} from '../../components/hocs/withSnackbar';
import {getErrorMessageForNonStandardAndStandardResponse} from '../../util/NetworkErrorUtil';
import {
  getSupplierBooking,
  getSupplierBookingCSVDownload,
  updateBookingStatus
} from '../../components/supplier-booking/requests/supplier-booking-requests';
import DownloadCSVButton from '../../components/report/export/DownloadCSVButton';
import {hasAdminAccess} from '../../util/AccountUtils';
import {useStyles} from './styles';
import type {Booking} from "../../types/Booking";


export const adjustBookingTypes = {DATE: "End Date", SPACES: "Number of Spaces"};

const ACTIVE_TAB = '0'
const PAYMENT_FAILED_TAB = '1';
const INACTIVE_TAB = '2';
const PENDING_TAB = '3';

export const statuses = {ACTIVE: 'ACTIVE', FAILED: 'FAILED', INACTIVE: 'INACTIVE', PENDING:'PENDING'};
export const updatePendingBookingActions = {APPROVE: "approve", DECLINE: "decline"};

const SupplierBooking = (props: { account: Account, snackbarShowMessage: () => void }) => {
  const {account, snackbarShowMessage} = props;

  const urlParams = new URLSearchParams(window.location.search);
  const defaultBookingNumber = urlParams?.get("bookingNumber") ? urlParams.get("bookingNumber") : "";
  const urlStatus = urlParams?.get("status") ? urlParams.get("status") : "";

  const getDefaultTab = useCallback((status) => {
    if (status) {
      const formattedStatus = status.toUpperCase();
      switch (formattedStatus) {
        case statuses.ACTIVE:
          return ACTIVE_TAB;
        case statuses.FAILED:
          return PAYMENT_FAILED_TAB;
        case statuses.INACTIVE:
          return INACTIVE_TAB;
        case statuses.PENDING:
          return PENDING_TAB;
        default:
          return ACTIVE_TAB;
      }
    } else {
      return ACTIVE_TAB
    }
  }, []);

  const defaultAdjustBooking = {open: false, booking: null, type: adjustBookingTypes.DATE};

  const [currentTab, setCurrentTab] = useState(getDefaultTab());
  const [activeBookings: Booking[], setActiveBookings] = useState([])
  const [inactiveBookings: Booking[], setInactiveBookings] = useState([]);
  const [failedBookings: Booking[], setFailedBookings] = useState([]);
  const [pendingBookings: Booking[], setPendingBookings] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const [failedPage, setFailedPage] = useState(0);
  const [inactivePage, setInactivePageable] = useState(0);
  const [pendingPage, setPendingPageable] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalBookingCount, setTotalBookingCount] = useState({active: 0, failed: 0, inactive: 0, pending: 0})
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    supplierAccountId: account?.id,
    startDate: '',
    endDate: '',
    buyerName: '',
    bookingNumber: defaultBookingNumber,
    selectedLocation: null,
    sortBy: '',
    equipmentType: '',
    brokered: false,
    frequency: '',
    sortDir: ''
  });
  const [reportUrl, setReportUrl] = useState(getSupplierBookingCSVDownload(query));
  const [adjustBooking, setAdjustBooking] = useState(defaultAdjustBooking);
  const [reloadAfterUpdate, setReloadAfterUpdate] = useState(true);

  const userIsAdmin = hasAdminAccess(account);

  const classes = useStyles();

  const handleTabChange = (_event, value) => {
    if (value !== undefined) {
      switch (value) {
        case '0' :
          setCurrentTab(ACTIVE_TAB)
          break;
        case '1':
          setCurrentTab(PAYMENT_FAILED_TAB)
          break;
        case '2':
          setCurrentTab(INACTIVE_TAB)
          break;
        case '3':
          setCurrentTab(PENDING_TAB)
          break;
        default:
          setCurrentTab(ACTIVE_TAB)
          break;
      }
    } else {
      setCurrentTab(ACTIVE_TAB)
    }
  };

  const onCloseDialog = () => {
    setAdjustBooking(defaultAdjustBooking)
  }

  const onDialogSuccess = () => {
    setReloadAfterUpdate(true);
    loadBookings()
  }

  const handleSelectAdjustBooking = (booking: Booking, adjustType) => {
    setAdjustBooking({open: true, booking: booking, type: adjustType})
  }

  const handleAdjustSpaces = (spaces) => {
    const {booking} = adjustBooking;

    let newBooking = {...booking, numberOfSpaces: spaces}

    setAdjustBooking(prev => ({...prev, booking: newBooking}))
  }

  const handleAdjustEndDate = (date) => {
    const {booking} = adjustBooking;

    let newBooking = {...booking, endDate: date}

    setAdjustBooking(prev => ({...prev, booking: newBooking}));
  }

  const handleUpdatePendingBooking = (booking, updateAction) => {
      let message = `Successfully ${updateAction === updatePendingBookingActions.APPROVE ? 'Approved' : 'Declined'} Booking ${booking.orderNumber}`;

      const params = {
          status: updateAction,
          body: {
              id: booking.id
          }
      }

      updateBookingStatus(params)
          .then(() => {
              loadBookings();
              snackbarShowMessage(message);
          })
          .catch((err) => {
              snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(err), "error", 15000)
          })
  
  }

  const loadBookings = useCallback(() => {
    if (reloadAfterUpdate) {
      const setBookingCount = (activeStatus, count) => {
        switch (activeStatus) {
          case statuses.ACTIVE:
            setTotalBookingCount(prev => ({...prev, active: count}));
            break;
          case statuses.FAILED:
            setTotalBookingCount(prev => ({...prev, failed: count}));
            break;
          case statuses.INACTIVE:
            setTotalBookingCount(prev => ({...prev, inactive: count}));
            break;
          case statuses.PENDING:
            setTotalBookingCount(prev => ({...prev, pending: count}));
            break;
        }
      }
      const handlePageReset = (activeStatus, totalPages) => {
        const pageValue = totalPages > 0 ? totalPages - 1 : 0;
        switch (activeStatus) {
          case statuses.ACTIVE:
            setActivePage(pageValue);
            break;
          case statuses.FAILED:
            setFailedPage(pageValue);
            break;
          case statuses.INACTIVE:
            setInactivePageable(pageValue);
            break;
          case statuses.PENDING:
            setPendingPageable(pageValue);
            break;
          default:
            break;
        }
      };

      const getBookings = (activeStatus, setBookings, page, size) => {
        const {id} = account;

        if (id) {
          setLoading(true);
          const requestParams = {
            supplierAccountId: account.id,
            locationId: query ? query.selectedLocation?.id : '',
            status: activeStatus,
            page: page,
            size: size,
            ...query,
          };

          getSupplierBooking(requestParams)
            .then(({body}) => {
              const {totalPages, count, page} = body;
              // If the page index is greater than the total pages
              // This will refresh the data and show the last page of results
              if (page + 1 > totalPages) {
                handlePageReset(activeStatus, totalPages)
              }
              setBookingCount(activeStatus, count)
              const data = body.content?.map((booking) => {
                return transformBooking(booking);
              });
              setBookings(data);
            })
            .catch(err => {
              snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(err), "error", 15000)
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }

      getBookings(statuses.ACTIVE, setActiveBookings, activePage, rowsPerPage);
      getBookings(statuses.FAILED, setFailedBookings, failedPage, rowsPerPage);
      getBookings(statuses.INACTIVE, setInactiveBookings, inactivePage, rowsPerPage);
      getBookings(statuses.PENDING, setPendingBookings, pendingPage, rowsPerPage);
    }
  }, [account, query, snackbarShowMessage, activePage, failedPage, inactivePage, rowsPerPage, reloadAfterUpdate]);

  useEffect(() => {
    if (urlStatus) {
      setCurrentTab(getDefaultTab(urlStatus))
    } else if (defaultBookingNumber) {
      if (activeBookings.length) {
        setCurrentTab(getDefaultTab(statuses.ACTIVE))
      }
      if (failedBookings.length) {
        setCurrentTab(getDefaultTab(statuses.FAILED))
      }
      if (inactiveBookings.length) {
        setCurrentTab(getDefaultTab(statuses.INACTIVE))
      }
      if (pendingBookings.length) {
        setCurrentTab(getDefaultTab(statuses.PENDING))
      }
    }
  }, [defaultBookingNumber, activeBookings, inactiveBookings, failedBookings, pendingBookings, getDefaultTab, urlStatus]);

  useEffect(() => {
    setReportUrl(getSupplierBookingCSVDownload(query))
  }, [query]);

  useEffect(() => {
    loadBookings();
  }, [loadBookings]);

  return (
    <Container className={classes.mainContainer}>
      <Grid item>
        <Typography variant={'h5'} component={'h1'}>Bookings</Typography>
      </Grid>
      <Grid item className={classes.filterContainer}>
        <SupplierBookingFilter setQuery={setQuery}/>
      </Grid>
      <Grid item className={classes.rightAlign}>
        {
          totalBookingCount.active !== 0 || totalBookingCount.inactive !== 0 || totalBookingCount.failed !== 0 ?
            <DownloadCSVButton
              title='Download Report'
              reportUrl={reportUrl}
              onError={(err) => snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(err, 'Failed to download report'))}
            />
            : ""
        }
      </Grid>
      <Grid item>
        <TabContext value={currentTab}>
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                aria-label={'Bookings Tabs'}
                textColor={'secondary'}
                variant={'fullWidth'}
              >
                <Tab value={'0'} label={activeBookings.length ? `Active (${totalBookingCount.active})` : 'Active'}/>
                <Tab value={'1'} label={failedBookings.length ? `Payment Failed (${totalBookingCount.failed})` : 'Payment Failed'}/>
                <Tab value={'2'} label={inactiveBookings.length ? `Inactive (${totalBookingCount.inactive})` : 'Inactive'}/>
                <Tab value={'3'} label={pendingBookings.length ? `Pending (${totalBookingCount.pending})` : 'Pending'}/>
              </Tabs>
            </Grid>
          </Grid>

          <TabPanel value={'0'} className={classes.tabPanel}>
            <BookingTab
              bookings={activeBookings}
              loading={loading}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={activePage}
              setPage={setActivePage}
              count={totalBookingCount.active}
              status={statuses.ACTIVE}
              userIsAdmin={userIsAdmin}
              handleSelectAdjustBooking={handleSelectAdjustBooking}
            />
          </TabPanel>
          <TabPanel value={'1'} className={classes.tabPanel}>
            <BookingTab
              bookings={failedBookings}
              loading={loading}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={failedPage}
              setPage={setFailedPage}
              count={totalBookingCount.failed}
              status={statuses.FAILED}
              userIsAdmin={userIsAdmin}
              handleSelectAdjustBooking={handleSelectAdjustBooking}
            />
          </TabPanel>
          <TabPanel value={'2'} className={classes.tabPanel}>
            <BookingTab
              bookings={inactiveBookings}
              loading={loading}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={inactivePage}
              setPage={setInactivePageable}
              count={totalBookingCount.inactive}
              status={statuses.INACTIVE}
              userIsAdmin={userIsAdmin}
              handleSelectAdjustBooking={handleSelectAdjustBooking}
            />
          </TabPanel>
          <TabPanel value={'3'} className={classes.tabPanel}>
            <BookingTab
              bookings={pendingBookings}
              loading={loading}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={pendingPage}
              setPage={setPendingPageable}
              count={totalBookingCount.pending}
              status={statuses.PENDING}
              userIsAdmin={userIsAdmin}
              handleSelectAdjustBooking={handleSelectAdjustBooking}
              handleUpdatePendingBooking={handleUpdatePendingBooking}
            />
          </TabPanel>
        </TabContext>
      </Grid>
      <AdjustBookingDialog
        open={adjustBooking.open}
        onConfirm={onDialogSuccess}
        onClose={onCloseDialog}
        type={adjustBooking.type}
        booking={adjustBooking.booking}
        handleAdjustEndDate={handleAdjustEndDate}
        handleAdjustSpaces={handleAdjustSpaces}
      />
    </Container>
  );
};

export default withSnackbar(SupplierBooking);
