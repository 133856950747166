import React, { useRef, useCallback, useMemo } from "react";
import { Grid } from "@mui/material";
import { SortFilter } from '@securspace/securspace-ui-kit';
import moment from "moment";
import { DateFormats } from "../constants/securspace-constants";
import { groupOption } from "./data";
import { getAllLocationsByBuyer } from "../location/requests/location-requests";
import useGetRequestBody from "../../hooks/useGetRequestBody";
import {withSnackbar} from "../hocs/withSnackbar";

const BuyerInterChangesFilters = React.memo(({
  buyerAccountId,
  setStartDate,
  setEndDate,
  setSortBy,
  setSelectedLocation,
  setEquipmentNumber,
  setEquipmentType,
  setSupplierName,
  snackbarShowMessage,
  setDriverName,
  setSortDir,
  supplierName,
  equipmentNumber,
  driverName,
  filterSelectOptions
}) => {

  const buyerLocations = useGetRequestBody(getAllLocationsByBuyer, [buyerAccountId], snackbarShowMessage);

  const locations = useMemo(() => {
    return [{id: "", locationName: "All"}, ...buyerLocations];
  },[buyerLocations]);

  const initialRender = useRef(true);

  const handleCallback = useCallback((e) => {
    const parseFilters = (filterArray) => {
      const supplierName = filterArray.find(item => item.name === "supplierName")?.value;
      const equipmentNumber = filterArray.find(item => item.name === "equipmentNumber")?.value;
      const driverName = filterArray.find(item => item.name === "driverName")?.value;

      return {
        supplierName,
        driverName,
        equipmentNumber,
      }
    };

    if (!initialRender.current) {
      setStartDate(e.startDate);
      setEndDate(e.endDate);
      setSortBy(e.sortBy);
      const filters = parseFilters(e.filters);
      setEquipmentNumber(filters.equipmentNumber);
      setEquipmentType(e.selectFilters?.find(item => item.name === "equipmentType")?.value);
      setSupplierName(filters.supplierName);
      setSelectedLocation(e.location);
      setDriverName(filters.driverName);
      setSortDir(e.sortDir)
    } else {
      initialRender.current = false;
    }
  }, []);

  const lastMonthDate = moment().add(-1, 'months').format(DateFormats.DAY).toString();
  const todayDate = moment().format(DateFormats.DAY).toString();

  const filterByOptions = useMemo(() => {
    return [
      { label: "Supplier Name", name: "supplierName", value: supplierName },
      { label: "Equipment Number", name: "equipmentNumber", value: equipmentNumber },
      { label: "Driver Name", name: "driverName", value: driverName }
    ];
  }, [supplierName, equipmentNumber, driverName]);


  return (
    <Grid item mb={4}>
      <SortFilter
        filterCallback={handleCallback}
        locations={locations}
        filterByOption={filterByOptions}
        defaultStartDate={lastMonthDate}
        defaultEndDate={todayDate}
        sortByOption={groupOption}
        filterBySelectOption={filterSelectOptions}
        defaultSortBy={"activityDate"}
        sortDir={"desc"}
       locationOption={[]}
      />
    </Grid>
  )
});

export default withSnackbar(BuyerInterChangesFilters);
