import request from "../util/SuperagentUtils";
import { getErrorMessageForNonStandardAndStandardResponse } from "../util/NetworkErrorUtil";

const BASE_URL = '/api/types';

const getAdminInvoiceGroupSummaryOptions: () => Promise = () => {
  return request.get(`${BASE_URL}/invoicesGroupSummaryOptions`);
};

const getAssetTypes: () => Promise = () => {
  return request.get(`${BASE_URL}/assets`);
}

const getTaxStatusTypes: () => Promise = () => {
  return request.get(`${BASE_URL}/taxStatus`);
}

const getAssetTypeGroupOptionsTypes: () => Promise = () => {
  return request.get(`${BASE_URL}/assetTypeGroupOptions`);
}

const getFormattedEquipmentTypes = (snackbarShowMessage) => {
    
  let newEquipment = [{value: 'ALL', label: 'All'}];

  getAssetTypes()
      .then(({body}) => {
          body.forEach(equipment => {
              newEquipment.push({value: equipment.key, label: equipment.value})
          })
      }).catch(err => {
          snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(err), 'error')
      })

  return newEquipment;
}

export {
  getAdminInvoiceGroupSummaryOptions,
  getAssetTypes,
  getTaxStatusTypes,
  getAssetTypeGroupOptionsTypes,
  getFormattedEquipmentTypes
}