import React, {useEffect, useState} from 'react';
import {Grid, Typography} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Money from 'js-money';
import {DAYS_PER_MONTH} from '../constants/securspace-constants';

const useStyles: () => {
  gridContainer: CSSStyleSheet,
  gridItem: CSSStyleSheet,
  pricePerMonth: CSSStyleSheet,
  saveMoney: CSSStyleSheet,
  perMonth: CSSStyleSheet,
  secondaryPrice: CSSStyleSheet,
} = makeStyles((theme) => ({
  gridContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%'
  },
  pricePerMonth: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.71428rem',
    lineHeight: '133.4%',
    display:"flex",
    alignItems:"flex-end"
  },
  saveMoney: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.1em',
    lineHeight: '157%',
    color: '#F44336',
  },
  perMonth: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '.6em',
  },
  secondaryPrice: {
    color: theme.palette.text.secondary,
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '1em',
    lineHeight: '157%',
    letterSpacing: '0.1px',
  }
}));

const LocationItemPriceDisplay = (props) => {
  const {pricePerMonth = 0, pricePerDay = 0} = props;
  const [formattedMonthly, setFormattedMonthly] = useState('');
  const [formattedDaily, setFormattedDaily] = useState('');
  const [formattedSaveMoneys, setFormattedSaveMoneys] = useState('');
  const classes = useStyles();

  useEffect(() => {
    const currency = Money.USD;
    const moneyPricePerMonth = new Money(pricePerMonth, currency);
    const moneyPricePerDay = new Money(pricePerDay, currency);

    setFormattedMonthly(moneyPricePerMonth.isPositive() ? `${currency.symbol}${moneyPricePerMonth}/mon` : '');

    setFormattedDaily(moneyPricePerDay.isPositive() ? `${currency.symbol}${moneyPricePerDay}/day` : '');

    if (pricePerDay && pricePerMonth) {
      const savings = moneyPricePerMonth.divide(DAYS_PER_MONTH, Math.round).subtract(moneyPricePerDay);
      if (savings.isPositive()) {
        setFormattedSaveMoneys(`Save ${currency.symbol}${savings}/day`);
      }
    }
  }, [pricePerMonth, pricePerDay]);



  return <Grid container className={classes.gridContainer}>
    <Grid item className={classes.gridItem}>
      <Typography className={classes.pricePerMonth}  color={'textPrimary'}>
      {formattedMonthly ? formattedMonthly?.split("/")[0] : formattedDaily?.split("/")[0]}
        <Typography component={"span"} className={classes.perMonth} >
        /{formattedMonthly ? formattedMonthly?.split("/")[1] : formattedDaily?.split("/")[1]}
        </Typography>
      </Typography>
      <Typography className={classes.secondaryPrice} variant={'subtitle2'} >{formattedMonthly && formattedDaily}</Typography>
    </Grid>
      <Typography className={classes.saveMoney} >{formattedSaveMoneys}</Typography>
  </Grid>
}

export default LocationItemPriceDisplay;
