import useUrlQueryParams from "./useUrlQueryParams";

/**
 * Returns a URLSearchParams object with the HubSpot UTM query parameters.
 * Includes the following query parameters:
 * utm_source, utm_medium, utm_campaign, utm_term, utm_content, utm_id
 * @returns {URLSearchParams}
 */
const useHubSpotUTMQueryParams = () => {
  const {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
    utm_id
  } = useUrlQueryParams();

  const queryParams = new URLSearchParams();
  if (utm_source) queryParams.set("utm_source", utm_source);
  if (utm_medium) queryParams.set("utm_medium", utm_medium);
  if (utm_campaign) queryParams.set("utm_campaign", utm_campaign);
  if (utm_term) queryParams.set("utm_term", utm_term);
  if (utm_content) queryParams.set("utm_content", utm_content);
  if (utm_id) queryParams.set("utm_id", utm_id);

  return queryParams;
};

export default useHubSpotUTMQueryParams;